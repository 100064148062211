<template>
  <el-drawer
    v-bind="{ ...$attrs, ...props }"
    class="ui-drawer"
    :close-icon="HamburgerIcon"
  >
    <template v-if="$slots.header" #header>
      <slot name="header"></slot>
    </template>
    <template #default>
      <slot name="default"></slot>
    </template>
    <template v-if="$slots.footer" #footer>
      <slot name="footer"></slot>
    </template>
  </el-drawer>
</template>
<script setup lang="ts">
import { drawerProps } from 'element-plus'
import HamburgerIcon from '@base/assets/img/svg/icon-menu.svg'

const props = defineProps({
  ...drawerProps,
  /**
   * Override default ElDrawer size prop
   */
  size: {
    type: String,
    default: '100%',
  },
})
</script>
<!-- eslint-disable vue/enforce-style-attribute -->
<style lang="postcss">
.ui-drawer.el-drawer {
  --el-drawer-bg-color: var(--brand-color-base-secondary);
  --el-drawer-padding-primary: 2rem;
  --ui-close-icon-size: 1.5rem;

  font-family: var(--font-family-secondary);
  border: 1px solid var(--brand-color-base-tertiary);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

  & .el-drawer__header {
    margin: 0;
    margin-bottom: 0.75rem;
  }
  & .el-drawer__body {
    padding-top: 0;
  }
  & .el-drawer__footer {
    padding-top: 0;
    margin-top: 0.75rem;
  }
  & .el-drawer__close-btn {
    color: var(--brand-color-text-tertiary);
    & .el-drawer__close {
      width: var(--ui-close-icon-size);
      height: var(--ui-close-icon-size);
    }
    &:hover {
      & .el-drawer__close {
        color: var(--brand-color-text-secondary);
      }
    }
    &:active,
    &:focus,
    &:focus-visible {
      & .el-drawer__close {
        color: var(--brand-color-text-primary);
      }
    }
  }
}
</style>
